.list-group-wrapper {
  max-height: 300px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #adb5bd transparent;
}
.list-group-wrapper::-webkit-scrollbar {
  width: 4px;
}
.list-group-wrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.list-group-wrapper::-webkit-scrollbar-thumb {
  background-color: #6c757d;
  border-radius: 2.5px;
}