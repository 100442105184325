#typeahead-container {
  position: relative;
  width: 100%;
  /*
  &:after
  {
      content:"";
      position:absolute;
      z-index: 4;
      height:20px;
      width:24px;
      right:0;
      top:18px;
      height:14px;
      background:$white;
  }
  */
}
#typeahead-container .spinner-wrapper {
  width: 14px;
  margin: 0 0.75rem;
}
#typeahead-container .spinner-wrapper .spinner {
  -webkit-transform: scale(0.7) translateY(4px) translateX(-1px);
          transform: scale(0.7) translateY(4px) translateX(-1px);
  -webkit-transform-origin: center center;
          transform-origin: center center;
}
#typeahead-container input {
  background-image: none !important;
  padding-right: 10px;
  text-overflow: ellipsis;
}
#typeahead-container svg * {
  fill: #f07b5d;
}
#typeahead-container.blur .list-group {
  display: none;
}