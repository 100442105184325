.nightsSelect {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.nightsSelect .title {
  height: inherit;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  font-size: 14px;
  width: 55px;
  min-width: 55px;
  font-size: 11px;
  color: #f07b5d;
  margin-right: 18px;
  position: relative;
}
.nightsSelect .title:before {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  height: 20px;
  width: 1px;
  background: #cccccc;
}
.nightsSelectInput {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 48px;
  -webkit-box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.25);
          box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.25);
}
.nightsSelectInput--input {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #fff;
  position: relative;
}
.nightsSelectInput--input .number {
  position: absolute;
  width: 50%;
  height: 100%;
  left: 0;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-right: 5px;
}
.nightsSelectInput--input .copy {
  position: absolute;
  width: 50%;
  height: 100%;
  right: 0;
  top: 0;
  border: 1px solid green;
}
.nightsSelectInput--add .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.nightsSelectInput--remove .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.nightsSelectInput .btn {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  outline: none !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 100%;
  font-size: 30px !important;
  font-weight: normal;
  line-height: 1;
  background: #0077CA;
  border-color: #0077CA;
  min-width: 44px;
}
.nightsSelectInput .v-number {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  font-size: 14px;
}