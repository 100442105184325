#children .dropdown .btn.closeDropdown {
  display: block;
  text-align: center;
  width: 100%;
  margin-top: 20px;
}
#children label {
  white-space: nowrap;
  margin-top: 0.5rem;
  font-size: 80%;
}
#children a {
  color: #fff !important;
}
#children .dropdown {
  background: #fff;
  border-radius: 6px;
  display: block;
}
#children .dropdown > .btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  text-align: left;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: normal;
  padding-left: 0;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  max-width: 100% !important;
}
#children .dropdown-menu.show {
  -webkit-transform: translateY(-15px) !important;
          transform: translateY(-15px) !important;
  left: 0;
  right: 0;
  top: 100% !important;
  border: 0px;
  border-top: 0;
  -webkit-box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.25);
          box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.25);
}
#children .occupancy-items {
  margin-top: 15px;
}
#children .occupancy-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 15px 0 15px 12px;
  border-top: 1px solid #CCCCCC;
}
@media (max-width: 1199.98px) {
#children .occupancy-item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 15px 0;
}
#children .occupancy-item > div:first-child {
    margin-bottom: 10px;
}
}
#children .occupancy-item.closeButton {
  border-top: 0;
  padding: 0 15px 15px 15px;
}
#children .occupancy-age {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 14px;
}
#children .occupancy-age:last-child {
  margin-bottom: -0.5rem;
}
#children .occupancy-age select {
  padding: 0;
  max-width: 100px;
  -webkit-box-shadow: none;
          box-shadow: none;
  font-size: 14px;
}
#children .form-group {
  width: 100%;
  margin-bottom: 0;
}