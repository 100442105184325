.vbst-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0.5rem 1.25rem 0.5rem 0.75rem !important;
}
.vbst-icon {
  margin-right: 5px;
  margin-top: 5px;
}
.vbst-icon svg {
  width: 35px;
  max-width: none;
}
.vbst-icon-geo svg {
  height: 35px;
}
.vbst-icon-here svg, .vbst-icon-property svg, .vbst-icon-insalabam svg {
  height: 24px;
}
.vbst-icon-not-results svg {
  width: 24px;
  height: 24px;
}