.custom-switch.md label {
  border-radius: 30px;
  width: 80px;
  height: 40px;
}
.custom-switch.md label:after {
  height: calc(100% - 8px);
  width: calc(50% - 8px);
  margin: 4px;
}
.custom-switch.md input:checked ~ label:after {
  margin-left: calc(100% - 4px);
}
.custom-switch.md svg {
  width: calc(15px * 100 / 110);
  height: calc(15px * 100 / 110);
}
.custom-switch.sm label {
  border-radius: 15px;
  width: 60px;
  height: 30px;
}
.custom-switch.sm label:after {
  height: calc(100% - 4px);
  width: calc(50% - 4px);
  margin: 2px;
}
.custom-switch.sm input:checked ~ label:after {
  margin-left: calc(100% - 2px);
}
.custom-switch.sm svg {
  width: calc(10px * 100 / 110);
  height: calc(10px * 100 / 110);
}
.custom-switch {
  margin: 0 0 0 0;
  padding: 0 0 0 0 !important;
}
.custom-switch label {
  margin: 0;
  cursor: pointer;
  position: relative;
  -webkit-transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
}
.custom-switch label:after {
  content: "";
  position: absolute;
  border-radius: 50%;
  background: #fff;
  left: 0;
  right: auto;
  top: 0;
  -webkit-transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
}
.custom-switch .switch-on {
  position: absolute;
  left: calc(14 * 100% / 110);
  top: 50%;
  -webkit-transform: translateY(-55%);
          transform: translateY(-55%);
  -webkit-transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
}
.custom-switch .switch-off {
  position: absolute;
  right: calc(14 * 100% / 110);
  top: 50%;
  -webkit-transform: translateY(-55%);
          transform: translateY(-55%);
  -webkit-transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
}
.custom-switch input {
  display: none;
}
.custom-switch input ~ label {
  background: rgba(51, 51, 51, 0.3);
}
.custom-switch input ~ label .switch-on {
  opacity: 0;
}
.custom-switch input ~ label .switch-off {
  opacity: 1;
}
.custom-switch input:checked ~ label {
  background: #78C94D;
}
.custom-switch input:checked ~ label .switch-on {
  opacity: 1;
}
.custom-switch input:checked ~ label .switch-off {
  opacity: 0;
}
.custom-switch input:checked ~ label:after {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}