@charset "UTF-8";
#search-form-datepicker #lock-14-alert {
  height: 1px;
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  visibility: hidden;
  position: absolute;
  left: 0;
  bottom: 0;
}
#search-form-datepicker #datepicker-container {
  position: relative;
}
#search-form-datepicker .datepicker-fields {
  position: relative;
  z-index: 100;
}
@media (min-width: 768px) and (max-width: 991.98px) {
#search-form-datepicker .datepicker-fields {
    position: absolute;
}
}
#search-form-datepicker .asd__wrapper--datepicker-open {
  border-radius: 6px;
  border-color: #F2F2F2;
  -webkit-box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.25);
          box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.25);
}
@media (min-width: 768px) {
#search-form-datepicker .asd__wrapper--datepicker-open {
    top: 1rem !important;
}
}
#search-form-datepicker .asd__wrapper--full-screen {
  z-index: 100;
  top: auto !important;
  background: #fff;
  border-top: 1px solid #CCCCCC;
  padding-top: 0;
}
#search-form-datepicker .asd__wrapper--full-screen .asd__mobile-header {
  display: none;
}
#search-form-datepicker .asd__wrapper--full-screen .asd__month-table {
  background: transparent;
}
#search-form-datepicker .asd__action-buttons {
  margin-bottom: 0;
}
#search-form-datepicker .asd__action-buttons button {
  font-size: 14px;
  padding: 0.35rem 0.85rem;
}
#search-form-datepicker .asd__action-buttons button:nth-child(1) {
  background: #f07b5d;
  border-radius: 6px;
  color: #fff;
  display: none;
}
#search-form-datepicker .asd__action-buttons button:nth-child(2) {
  -webkit-box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.25);
          box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  display: block;
  width: calc(100% - 2rem);
  text-align: center;
  float: none;
  margin: 0 auto;
  right: auto;
  left: auto;
  border: 2px solid transparent;
  padding: 1rem 1.95rem;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 1rem;
  font-weight: normal;
  background: #0077CA;
  border-radius: 6px;
  color: #fff !important;
  -webkit-transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
}
#search-form-datepicker .asd__action-buttons button:nth-child(2):hover {
  text-decoration: none;
  background-color: #0068b1;
}
#search-form-datepicker .asd__week td {
  border: 0 !important;
}
#search-form-datepicker .asd__month-name {
  font-weight: normal;
  font-size: 14px;
  margin: 0 0 50px;
}
#search-form-datepicker .asd__mobile-close {
  -webkit-transform: scaleX(1.5);
          transform: scaleX(1.5);
  margin-right: 12px;
  background-color: transparent;
  color: #999999;
}
#search-form-datepicker .asd__change-month-button {
  background: transparent;
}
#search-form-datepicker .asd__change-month-button button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
  height: 37px;
  width: 37px;
  border-radius: 50%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 991.98px) {
#search-form-datepicker .asd__wrapper {
    border: 1px solid #dedede;
    top: 50% !important;
    bottom: auto;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    max-width: calc(100% - 2rem);
    margin: auto;
}
#search-form-datepicker .asd__wrapper .asd__inner-wrapper {
    -webkit-transform: translateX(-0.8rem);
            transform: translateX(-0.8rem);
}
#search-form-datepicker .asd__wrapper .asd__days-legend {
    max-width: 100%;
}
}
@media (max-width: 767.98px) {
.mobile-search-on .asd__wrapper--datepicker-open {
    top: 70px !important;
    z-index: 200;
    border-radius: 0;
}
}
@media (min-width: 768px) and (max-width: 991.98px) {
.mobile-search-on .asd__wrapper--datepicker-open {
    position: relative !important;
    left: 0;
    right: 0;
    margin: 15px auto;
    top: 0 !important;
    padding-top: 0;
    width: calc(100% + 60px) !important;
    margin-left: -30px;
    padding-left: 0px;
}
.mobile-search-on .asd__wrapper--datepicker-open .asd__month,
.mobile-search-on .asd__wrapper--datepicker-open .asd__datepicker-header {
    margin-left: 15px;
}
.mobile-search-on .asd__wrapper--datepicker-open .asd__datepicker-header {
    margin-right: 15px;
}
}

/* inutile: tawk parte ogni volta con un id diverso
@include media-breakpoint-only(md)
{
    // sposto la chat se il calendario è aperto
    #LLZKOxw-1602248103032
    {
        transition:$transition-base;
    }
    body.datepickerOpen #LLZKOxw-1602248103032
    {
        transform:translateY(80px);
    }
}
*/
.picker-wrapper {
  padding-left: 0;
  padding-right: 0;
}
.picker-wrapper .picker-alert .alert {
  padding: 6px 12px;
  margin-top: 12px;
  margin-bottom: 0;
  font-size: 14px;
}
.picker-wrapper .picker-alert .alert b {
  color: rgba(52, 58, 64, 0.8);
}
@media (min-width: 768px) {
.picker-wrapper {
    height: 1px;
    overflow: visible;
    padding-left: 0;
    width: 100%;
    max-width: 300px;
    padding: 0;
}
.picker-wrapper .asd__wrapper--datepicker-open {
    position: relative !important;
}
.picker-wrapper .asd__wrapper--datepicker-open + .picker-alert {
    opacity: 1;
    width: 300px !important;
    position: relative;
    z-index: 101;
}
.picker-wrapper .picker-alert {
    opacity: 0;
    background-color: #fff;
    padding: 10px;
    padding-top: 20px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}
.picker-wrapper .picker-alert .alert {
    margin-top: 0;
}
}