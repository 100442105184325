#search-form {
  background-color: #F2F2F2;
  padding-top: 20px;
}
#search-form.search-form-be input, #search-form.search-form-be button, #search-form.search-form-be select {
  font-size: 1rem !important;
}
#search-form .info {
  font-size: 12px;
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: 8px;
  text-align: left;
  line-height: 120%;
}
#search-form .bookingEngineSecondRow {
  display: none;
  clear: both;
  width: 100%;
  min-width: 100%;
  text-align: left;
  padding-top: 1rem;
}
@media (min-width: 992px) {
#search-form .bookingEngineSecondRow.desktop {
    display: block;
}
}
#search-form .bookingEngineSecondRow.mobile {
  padding: 0.5rem 0;
}
#search-form .bookingEngineSecondRow.mobile .bookingFocusSwitch {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (min-width: 992px) {
#search-form .bookingEngineSecondRow.mobile {
    display: none !important;
}
}
#search-form .bookingFocusSwitch {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#search-form .bookingFocusSwitch .custom-switch {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#search-form .input-icon svg {
  max-width: 90%;
  margin: 0 auto;
}
#search-form .input-icon svg path {
  fill: #f07b5d;
}
#search-form #search-form-autocomplete {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  justify-items: center;
}
@media (min-width: 768px) {
.homepage #search-form #input-nights-desktop {
    height: 100%;
}
}
@media (min-width: 1600px) {
.search #search-form #input-nights-desktop {
    height: 100%;
}
}
#search-form #input-nights-desktop .input-with-icon,
#search-form #input-nights-desktop .custom-select {
  height: 100%;
  text-overflow: ellipsis;
}
#search-form #input-nights-desktop,
#search-form #input-adults,
#search-form #input-occupancy {
  position: relative;
}
#search-form #toggle-search-settings {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -ms-flex-line-pack: center;
      align-content: center;
  width: 46px;
}
#search-form #toggle-search-settings svg {
  height: auto;
}
#search-form #toggle-search-settings svg path {
  fill: #f07b5d;
}
#search-form .btn-search {
  display: block;
  width: 100%;
}
#search-form .search-form-item {
  margin-bottom: 14px;
}
#search-form #search-form-filters button {
  margin-right: 0;
  display: block;
  width: 100%;
}
#search-form .searchFormHidden {
  display: none;
}
#search-form.visible .searchFormHidden {
  display: block;
}
@media (min-width: 992px) {
#search-form {
    padding-bottom: 20px;
}
#search-form .searchFormHidden {
    display: block;
}
#search-form .search-form-item {
    width: 100%;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
}
#search-form .search-form-item:not(:last-child) {
    padding-right: 30px;
}
#search-form #toggle-search-settings {
    display: none;
}
#search-form form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
#search-form form #search-form-autocomplete {
    width: 20%;
    -webkit-box-flex: 20%;
        -ms-flex: 20%;
            flex: 20%;
}
#search-form form #search-form-datepicker {
    width: 15%;
    -webkit-box-flex: 15%;
        -ms-flex: 15%;
            flex: 15%;
    position: relative;
    z-index: 100;
}
#search-form form #search-form-nights {
    width: 12.5%;
    -webkit-box-flex: 12.5%;
        -ms-flex: 12.5%;
            flex: 12.5%;
}
#search-form form #search-form-occupancy {
    width: 20%;
    -webkit-box-flex: 20%;
        -ms-flex: 20%;
            flex: 20%;
}
#search-form form #search-form-buttons {
    width: 12%;
    -webkit-box-flex: 12%;
        -ms-flex: 12%;
            flex: 12%;
}
#search-form form #search-form-filters {
    width: 8%;
    -webkit-box-flex: 8%;
        -ms-flex: 8%;
            flex: 8%;
}
}
@media (max-width: 991.98px) {
#search-form {
    padding-left: 30px;
    padding-right: 30px;
}
#search-form.visible {
    background: #F2F2F2;
    position: relative;
    z-index: 1;
}
}
@media (max-width: 991.98px) {
#searchBar {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
    background: #F2F2F2;
}
.breadcrumbs {
    margin: auto;
}
}
#input-nights-mobile {
  display: block;
}
@media (min-width: 768px) {
#input-nights-mobile {
    display: none;
}
}
#input-nights-desktop {
  display: none;
}
@media (min-width: 768px) {
#input-nights-desktop {
    display: block;
}
}
@media (max-width: 767.98px) {
#search-form .input-icon {
    width: 55px;
    text-align: center;
}
#search-form .input-icon svg {
    margin: 0 auto;
}
}
@media (min-width: 768px) {
#search-form .input-icon svg {
    height: 24px;
    width: auto;
    margin: 0 11px 0 10px;
}
#search-form #input-occupancy .input-icon svg {
    width: 24px;
    height: auto;
}
}