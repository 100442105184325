.vbt-autcomplete-list[data-v-538fedf4] {
  padding-top: 5px;
  position: absolute;
  z-index: 999;
  padding: 0;
  background-color: #fff;
  overflow: hidden;
}

/* Fake for IE. because it doesn't emit the hit event when using display:none */
.vbt-autcomplete-list--hidden[data-v-538fedf4] {
  -webkit-animation-name: hide-data-v-538fedf4;
          animation-name: hide-data-v-538fedf4;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}
@-webkit-keyframes hide-data-v-538fedf4 {
to {
    visibility: hidden;
    display: none;
}
}
@keyframes hide-data-v-538fedf4 {
to {
    visibility: hidden;
    display: none;
}
}