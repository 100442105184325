#modal-filters #categories .custom-control-label {
  text-transform: lowercase;
}
#modal-filters .col-Property {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
}
#modal-filters .col-Rate {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
}
#modal-filters .col-Refundable {
  -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
          order: 4;
}
#modal-filters .col-Type {
  -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
          order: 3;
}
#modal-filters .filterLabel {
  font-weight: medium;
}
#modal-filters label {
  font-size: 12px;
  line-height: 120%;
}
#modal-filters .custom-control:not(:last-child) label {
  margin-bottom: 1rem;
}
#modal-filters .custom-control-label {
  padding-top: 4px;
}
#modal-filters footer {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}